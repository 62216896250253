<template>
  <register-container>
    <template slot="header"> {{ pageTitle || $t('registration.registerSignup.title') }} </template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <v-container class="pa-1">
      <v-form>
        <v-text-field
          :class="tenantUrl + '-theme-text-field'"
          outlined
          v-model="email"
          :placeholder="$t('main.email')"
          :error-messages="signUpEmailErrors"
          :value="email"
          @change="onEmailInput"
          @blur="onEmailInput"
        ></v-text-field>
        <password-check
          @password-update="onPasswordUpdate"
          @auto-password="onAutoPassword"
        ></password-check>
      </v-form>

      <div class="d-flex flex-column align-center">
        <v-checkbox
          v-model="consent"
          :error-messages="consentErrors"
          @change="$v.consent.$touch()"
          @blur="$v.consent.$touch()"
        >
          <div slot="label">
            {{ $t('registration.registerSignup.consent.readAndAgreed') }}
            <info-dialog :dialogContent="consentForm">
              <template slot="open-dialog"
                ><a href="#">{{ $t('registration.registerSignup.consent.title') }}</a></template
              >

              <template slot="dialog-title">{{
                $t('registration.registerSignup.consent.title')
              }}</template>
            </info-dialog>
          </div>
        </v-checkbox>
      </div>
      <div class="disclaimer-container d-flex justify-center mt-6">
        <div class="disclaimer text-center pa-0">
          <b>{{ $t('main.disclaimer') }}</b
          >: {{ $t('registration.registerSignup.disclaimer') }}
        </div>
      </div>
    </v-container>
  </register-container>
</template>

<style scoped>
.close-tos {
  cursor: pointer;
}
.disclaimer-container {
  width: 100%;
}
.disclaimer {
  width: 600px;
  font-size: 0.8em;
}
@media all and (max-width: 600px) {
  .disclaimer {
    max-width: 95%;
  }
}
</style>

<script>
import { required, email, maxLength, requiredIf } from 'vuelidate/lib/validators';

import { VUELIDATE_ERRORS } from '../constants/validation';
import { REGISTRATION_ERRORS } from '../constants/errors';

import PasswordCheck from '../components/PasswordCheck.vue';
import RegisterContainer from '../components/RegisterContainer.vue';
import consentForm from '../constants/legal';
import InfoDialog from '../components/InfoDialog.vue';

export default {
  name: 'RegisterSignUp',
  components: { InfoDialog, PasswordCheck, RegisterContainer },
  mixins: [VUELIDATE_ERRORS],
  data() {
    return {
      autoPassword: true,
      consent: false,
      dialog: false,
      duplicateEmail: null,
      email: this.$store.state.registration.patientData.email,
      emailError: null,
      isEmailOptional: false,
      loading: false,
      newUser: null,
      password: null,
      consentForm,
    };
  },
  props: {
    checkedPassword: String,
  },
  computed: {
    completed() {
      if (
        (this.email || this.isEmailOptional) &&
        this.consent &&
        !this.$v.$invalid &&
        (this.password || this.autoPassword)
      ) {
        return true;
      }
      return false;
    },
    isEmailTaken() {
      return this.$store.state.registration.errors.errorType === REGISTRATION_ERRORS.EMAIL_TAKEN;
    },
    isRegistrationErrors() {
      return this.$store.state.registration.errors.registerError;
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
  validations() {
    return {
      email: {
        required: requiredIf(() => !this.isEmailOptional),
        email,
        maxLength: maxLength(256),
        uniqueEmail() {
          this.$v.email.$touch();
          return !this.emailError || this.email !== this.duplicateEmail;
        },
      },
      consent: {
        required,
      },
    };
  },
  mounted() {
    this.$store.commit('setCompleted', this.completed);
    this.$store.commit('setOnNextClick', this.onNextClick);
    if (!this.isPageflowV2) {
      this.$store.watch(
        (state) => state.registration.errors.emailError,
        (value) => {
          if (value) {
            this.emailError = value;
            this.duplicateEmail = this.email;
            this.$v.email.touch();
          }
        }
      );
    }
  },
  methods: {
    onAutoPassword(value) {
      this.autoPassword = value;
    },
    onEmailInput() {
      this.$v.email.$touch();
      this.emailError = null;
    },
    onPasswordUpdate(password) {
      this.password = password;
    },
    async onNextClick() {
      this.$store.commit('setLoading', true);
      this.$store.commit('addOtherPatientNote', {
        newNoteText: `User has read & accepted the terms of service`,
        noteTags: ['Consent'],
      });
      const nextRoute = await this.$store.dispatch('completeRegistration', {
        memberInfo: { email: this.email, password: this.autoPassword ? undefined : this.password },
        currentRoute: this.$route.name,
      });
      this.$store.commit('setCompleted', false);
      if (!nextRoute && !this.isPageflowV2) return;
      if (this.isPageflowV2 && this.isRegistrationErrors) return;
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: nextRoute });
      }

      this.$store.commit('setLoading', false);
    },
  },
  watch: {
    completed(val) {
      this.$store.commit('setCompleted', val);
    },
    isEmailTaken: {
      immediate: true,
      handler(val) {
        if (!this.isPageflowV2) return;
        if (val) {
          this.emailError = this.$t(`registration.errors.${REGISTRATION_ERRORS.EMAIL_TAKEN}`);
          this.duplicateEmail = this.email;
          this.$v.email.$touch();
        }
      },
    },
  },
};
</script>

<template>
  <div>
    <!-- Desktop -->
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          :class="this.tenantUrl + '-theme-text-field'"
          :placeholder="autoPassword ? 'Automatic password selected' : 'Password'"
          @input="updatePassword"
          :disabled="autoPassword"
          outlined
          type="password"
          v-model="password"
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          :class="tenantUrl + '-theme-text-field'"
          :placeholder="autoPassword ? 'Automatic password selected' : 'Retype Password'"
          @input="updatePassword"
          :disabled="autoPassword"
          outlined
          type="password"
          v-model="passwordConfirmation"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex justify-center pa-0 px-5"
        ><v-switch
          @change="setAutoPassword($event)"
          v-model="autoPassword"
          label="Generate secure password and email it to me"
        ></v-switch>
      </v-col>
      <v-col v-if="!autoPassword" cols="12" class="py-0">
        <ul class="complexity">
          <v-row dense>
            <v-col cols="12" md="6">
              <li :class="{ right: hasNumber }">
                {{ 'Password has a number' }}
              </li>
            </v-col>
            <v-col cols="12" md="6">
              <li :class="{ right: hasLowercase }">
                {{ 'Password has lowercase' }}
              </li>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <li :class="{ right: hasUppercase }">
                {{ 'Password has uppercase' }}
              </li>
            </v-col>
            <v-col cols="12" md="6">
              <li :class="{ right: hasLength }">
                {{ 'At least 10 characters long' }}
              </li>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <li :class="{ right: passwordMatches }">
                {{ 'Password matches' }}
              </li>
            </v-col>
          </v-row>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.complexity li {
  list-style: none;
}

.complexity li:before {
  content: '⨯';
  display: inline-block;
  margin-right: 0.5rem;
  width: 1em;
}

.complexity li.right {
  color: var(--v-primary-base);
}

.complexity li.right:before {
  content: '✓';
}
</style>
<script>
import { HAS_NUMBER, HAS_UPPERCASE, HAS_LOWERCASE } from '../constants/regex';

export default {
  name: 'PasswordCheck',
  data() {
    return {
      autoPassword: true,
      password: '',
      passwordConfirmation: '',
    };
  },
  props: {
    open: Boolean,
  },
  methods: {
    setAutoPassword(value) {
      this.$emit('auto-password', value);
      this.password = '';
      this.passwordConfirmation = '';
    },
    updatePassword() {
      this.$emit('password-update', this.passwordValue);
    },
  },
  computed: {
    passwordValue() {
      if (this.autoPassword) {
        return this.password;
      }
      return this.allChecks.some((v) => !v) ? '' : this.password;
    },
    passwordMatches() {
      return this.password && this.password === this.passwordConfirmation;
    },

    hasUppercase() {
      return HAS_UPPERCASE.test(this.password);
    },

    hasLowercase() {
      return HAS_LOWERCASE.test(this.password);
    },

    hasNumber() {
      return HAS_NUMBER.test(this.password);
    },

    hasLength() {
      return this.password.length >= 10;
    },

    allChecks() {
      return [
        this.hasLength,
        this.hasLowercase,
        this.hasNumber,
        this.hasUppercase,
        this.passwordMatches,
      ];
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
  watch: {
    open(value) {
      if (!value) {
        this.password = '';
        this.passwordConfirmation = '';
      }
    },
  },
};
</script>
